import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSliderNewCategoriesFeaturedIn from "components/SectionSliderNewCategoriesFeaturedIn/SectionSliderNewCategoriesFeaturedIn";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import React, { FC, Fragment,ReactNode,useEffect,useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionGridFeatureStory from "./SectionGridFeatureStory";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import axios from 'axios'; 
import { Route, Redirect,useHistory} from 'react-router-dom';
import InstagramEmbed from 'react-instagram-embed';


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
  },
  {
    id: "2",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
  },
  {
    id: "3",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/7-NEWS-black.png",
  },
  {
    id: "4",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Stuff.png",
  },
  {
    id: "5",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/Urban-List.png",
  },
  {
    id: "6",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
  },
  {
    id: "7",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
  },
  {
    id: "8",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
  },
  {
    id: "9",
    href: "#",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
  }
];

const DEMO_CATS_2: TaxonomyType[] = [];
const AIRBNB_REVIEWS: TaxonomyType[] = [];
function PageHome() {
  const [DEMO_CATS_2ww, setDiscovery]               = useState();
  const [isWebsiteName, setWebsiteName]             = useState('tinyaway');
  const [socialLinks, setSocialLinks]               = useState({facebook:"",instagram:"",youtube:""});
  const [metaDetails, setMetaDetails]               = useState({meta_title:"",meta_desc:""});
  const history                                     = useHistory();
  let targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en";
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetTravelInspirations')
    .then(res => {
          //console.log(DEMO_CATS_2ww); 
          //console.log(res.data); 
          setDiscovery(res.data.data);
          setWebsiteName(res.data.isWebsiteName);
          setSocialLinks(res.data.social_links);
          setMetaDetails(res.data.metaDetails);
    });   
  }, []);

  const [AIRBNB_REVIEWS, setAirbnbReviews] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetAirbnbReviews')
    .then(res => {
          //console.log(AIRBNB_REVIEWS); 
          //console.log(res.data); 
          setAirbnbReviews(res.data);
    });   
  }, []);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const showOnMap = () => {
      setShowFullMapFixed(true);
      history.push("/listing-stay-map");
  }

  const renderLazarusHopOn= () => {
    return(
      <div className="container-fluid flex items-center justify-center lg:mb-0 lg:space-y-0 mb-24 relative space-y-24">
        <div className="banner-h-300">
          <div className="sub-banner-hero">
            <h2>Hop on a Ferry to Lazarus Island</h2>
            <p>Whether it's a romantic escape, bonding time with family or friends, or a perfect spot for some alone time, 
            “Tiny Houses” @ Lazarus Island provides the perfect backdrop for creating memorable moments in paradise!</p>
            <p className="mt-5">Travelling Time (Ferry Ride):</p>
            <div className="btn-f-sub-b">
              <span>20 mins from <a target="_blank" href="https://marinasouthferries.com/pages/ferry-schedule">Marina South Pier </a></span>
              <span>15 mins from<a target="_blank" href="https://marinasouthferries.com/products/tinyaway"> Sentosa Cove</a></span>
            </div>
          </div>
          <img src="https://cdn.tinyaway.com/common/2023/04/Photo21682476776218.jpg"></img>
        </div>
      </div>
    );
  }

  const renderLazarusWhoWeAre = () => {
    return(
      <div className="container-fluid">
        <div className="row-flex-who-are-we">
          <h2>Who Are We?</h2>
          <p>The first company in Singapore to integrate the concept of “Tiny Houses” with eco-tourism. </p>
          <p>“Tiny Houses” are part of an international trend of simplifying lives by reducing the size, and therefore the resource consumption, of homes without sacrificing the quality of life.</p>
        </div>
        <div className="col-row-who-are-we">
          <div>
            <img src="https://cdn.tinyaway.com/common/2023/04/Asset21682043806860.jpg" />
            <h3>8</h3>
            <p>Countries</p>
          </div>
          <div>
            <img src="https://cdn.tinyaway.com/common/2023/04/Assests-41682043845008.jpg" />
            <h3>200+</h3>
            <p>Tiny House</p>
          </div>
          <div>
            <img src="https://cdn.tinyaway.com/common/2023/04/Asset 31682043896490.jpg" />
            <h3>21K+</h3>
            <p>Happy City Dwellers</p>
          </div>
          <div>
            <img src="https://cdn.tinyaway.com/common/2023/04/Asset 31682043954910.jpg" />
            <h3>4.8/5</h3>
            <p>Average Rating</p>
          </div>
        </div>
        <div className="mb-16 mt-11 text-center text-boxp ">
          <p>We offer you a chance to experience the perfect escape from a hectic and digitally-laden lifestyle.</p>
          <a href="/escape/lazarus-island" className="btn-get-mid">Book Tiny Getaway</a>
        </div>
      </div>
    );
  }

  const renderLazarusWhoWeAreSecond = () =>{
    return(
      <div className="container-fluid">
          <div className="row-2-flex">
            <div className="left-border"><img src="https://cdn.tinyaway.com/common/2023/04/photo 11682476317280.jpeg" /></div>
            <div className="right-icon-text-wav">
              <h2>Amenities</h2>
              <div>
                <ul>
                  <li><div className="image-icon"><i className="text-3xl all-cons Airconditioning"></i></div><span>Air conditioning</span></li>
                  <li><div className="image-icon"><i className="text-3xl all-cons Kitchen"></i></div><span>Kitchen</span></li>
                  <li><div className="image-icon"><i className="text-3xl all-cons Heating"></i></div><span>Heating</span></li>
                  <li><div className="image-icon"><i className="text-3xl all-cons Familykidfriendly"></i></div><span>Family/kid friendly</span></li>
                  {/* <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Essentials"></i></div><span>Essentials</span></li> */}
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Shampoo"></i></div><span>Shampoo</span></li>
                  <li><div className="image-icon"><img src="https://cdn.tinyaway.com/common/2023/04/Asset71682044310400.png" alt="" /></div><span> Hangers</span></li>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Smokealarm"></i></div><span> Smoke detector</span></li>
                  
                </ul>
                <ul>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Fireextinguisher"></i></div><span>Fire extinguisher</span></li>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Laptopfriendlyworkspace"></i></div><span>Laptop friendly workspace</span></li>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons TV"></i></div><span>Television</span></li>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Wifi"></i></div><span>Wi-Fi</span></li>
                  <li><div className="image-icon"><i className="text-2xl las la-bed "></i></div><span>Bed (Sleeps 1-2 Adults)</span></li>
                  <li><div className="image-icon"><img src="https://cdn.tinyaway.com/common/2023/04/Asset151682044366494.png" alt="" /></div><span>Sofa Bed (Sleeps 1 Adult / 2 Kids)</span></li>
                  <li><div className="image-icon"><i className="text-4xl text-neutral-6000 all-cons Fireextinguisher"></i></div><span> First aid kit</span></li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    );
  }

  const renderLazarusFootPrint = () =>{
    return(
      <div className="container-fluid banner-box">
        <div className="boxbarnner">
          <img src="https://cdn.tinyaway.com/common/2023/04/body-image1682043457535.jpg" />
        </div>
        <div className="row-text-banner">
          <div className="row-flex-who-are-we Reducing-Footprints">
            <h2>Reducing Carbon Footprints</h2>
          </div>
          <div className="col-row-who-are-we">
              <div>
                <img src="https://cdn.tinyaway.com/common/2023/04/Asset81682044448810.jpg" />
                <h3>Solar <br />System</h3>
                <p>Main source of power<br />Relax, we have back up for contingency</p>
              </div>
              <div>
                <img src="https://cdn.tinyaway.com/common/2023/04/Asset91682044486118.jpg" />
                <h3>High Tech Food<br />Waste Recycling Systems</h3>
                <p>Converts food waste to compost within 24 hours</p>
              </div>
              <div>
                <img src="https://cdn.tinyaway.com/common/2023/04/Asset101682044521043.jpg" />
                <h3>Sustainable Composite Building Materials</h3>
                <p>Durable and repurposed from recycled plastic and wood fibre</p>
              </div>
              <div>
                <img src="https://cdn.tinyaway.com/common/2023/04/Asset111682044557667.jpg" />
                <h3>Biodegradable Necessities</h3>
                <p>Biodegradable shampoo and body wash are prepared in all units</p>
              </div>
          </div>
          <div className="mb-16 mt-11 text-center text-boxp ">
            <a href="/escape/lazarus-island" className="btn-get-mid">BOOK YOUR TINY LIVING</a>
          </div>
        </div>
      </div>
    );
  }

  const renderLazarusFeaturedOn = () =>{
    return(
      <div className="container-fluid banner-box">
        <div className="boxbarnner">
          <img src="https://cdn.tinyaway.com/common/2023/04/body-image1682043457535.jpg" />
        </div>
        <div className="row-text-banner">
          <div className="row-flex-who-are-we Reducing-Footprints">
            <h2>{targetlanguage=='zh_tw'?'作為精選':'As Featured On'}</h2>
          </div>
          <div className="col-row-who-are-we">
          <div className="container-full">
            <img src="https://cdn.tinyaway.com/common/2023/04/11682044672724.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/21682044705318.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/31682044740094.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/41682044776593.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/51682044826473.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/61682044874896.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/71682044913895.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/81682044970790.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/91682045005035.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/101682045115274.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/111682045193146.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/121682046555290.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/131682046594831.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/141682046624507.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/141682046624507.jpg"  alt="" />
            <img src="https://cdn.tinyaway.com/common/2023/04/161682046761630.jpg"  alt="" />
          </div>
          </div>
        </div>
      </div>
    );
  }

  const renderGrampiansSnap = () =>{
    return(
      <div className="Section-grid-home grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 ">
        <div className="relative w-full rounded-2xl overflow-hidden">
          <a href="/pages/accommodation">
          <img className="object-cover w-full h-full"  src="https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2023/01/152/PXL_20220728_0757597831673943230860.jpg"></img>
        <div className="absolute bottom-2 p-5 text-bg-color w-full z-50">
          <h4 className="font-20-bold">STAY</h4>
          <p>Accommodation Options </p>
        </div>
        <div className="color-overly"></div>
        </a>
        </div>
        <div className="relative w-full rounded-2xl overflow-hidden">
          <a href="/pages/facilities">
          <img className="object-cover w-full h-full" src="https://pictures.tinyaway.com/filters:format(jpg)/fit-in/400x400/ThingsWedid/Images/2023/01/464/PXL_20220730_042732185.MOTION-01.COVER1673956829448.jpg"></img>
        <div className="absolute bottom-2 p-5 text-bg-color w-full z-50">
          <h4 className="font-20-bold">ESCAPE</h4>
          <p>Facilities @ Grampians Edge</p>
        </div>
        <div className="color-overly"></div>
        </a>
        </div>
        <div className="relative w-full rounded-2xl overflow-hidden">
          <a href="/pages/things-to-do">
          <img className="object-cover w-full h-full"  src="https://pictures.tinyaway.com/filters:format(jpg)/fit-in/400x400/ThingsWedid/Images/2023/01/464/PXL_20220730_042732185.MOTION-01.COVER1673956829448.jpg"></img>
        <div className="absolute bottom-2 p-5 text-bg-color w-full z-50">
          <h4 className="font-20-bold">PLAY</h4>
          <p>Things To Do</p>
        </div>
        <div className="color-overly"></div>
        </a>
        </div>
      </div>
    );
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>{metaDetails.meta_title}</title>
      </Helmet>
      <BgGlassmorphism />
      
      <SectionHero className="pt-10 lg:pt-24 pb-0" />
      {isWebsiteName=='escape_lazarus' ?
        <>
          {renderLazarusWhoWeAre()}
          {renderLazarusWhoWeAreSecond()}
          {renderLazarusFootPrint()}
          {renderLazarusHopOn()}
          {renderLazarusFeaturedOn()}
        </>
      :""}
      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="relative py-16 mb-5">
          <BackgroundSection />
          <SectionGridFeaturePlaces  />
        </div>
        <SectionGridFeatureStory  />
        {isWebsiteName=='escape_grampians' ?
          <>
            {renderGrampiansSnap()}
          </>
        :''
        }
        <SectionOurFeatures />
        {isWebsiteName=='tinyaway' ?
          <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2ww}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Travel Inspirations"
            subHeading="Popular things to do that Tiny Away recommends for you"
            sliderStyle="style2"
          />
        </div>
        :
        ""
        }

        <SectionSubscribe2 />
        <div className="nc-relative flex flex-col lg:flex-row lg:items-center socal-links">
          <div className="flex-grow">
           <a href={socialLinks?.facebook} target="_blank"> <i className="la la-facebook"></i></a>
           <a href={socialLinks?.instagram} target="_blank">  <i className="la la-instagram"></i></a>
           <a href={socialLinks?.youtube} target="_blank">  <i className="la la-youtube "></i></a>
          </div>
          <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
            <h2 className="font-semibold text-4xl">{targetlanguage=='zh_tw'?'追蹤 Tiny Away Taiwan ！':'Follow us on Social Media'}</h2>
            {targetlanguage=='zh_tw'?
            <>
            <p>接收最新完成的移動微型屋和即時的特別優惠！</p>
            <p>不管是台灣，甚至是全世界的移動微型屋的優惠碼都有</p>
            </>
            :
            <>
            <p>Receive first hand information on the latest tiny house additions and promotions. Be the first to receive limited discount codes on tiny house getaway and tiny house rentals in Australia.</p>
            </>
            }
            
          
          </div>
        </div>
        {isWebsiteName=='tinyaway' || isWebsiteName=='escape_grampians' ?
        <SectionAibnbReviews
          heading="featured In "
          subHeading=""
          className="hedding-center"
          categoryCardType="card6"
          categories={DEMO_CATS}
          itemPerRow={5}
        />
        :""
      }
      adas
      <InstagramEmbed
  url='https://www.instagram.com/reels'
  clientAccessToken='7898758410209027|0441462100323c12d558fc650fd5249e'
  maxWidth={320}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
      {isWebsiteName=='tinyaway' || isWebsiteName=='escape_grampians' ?
        <SectionAibnbReviews
        heading="What Are Our Guests Saying"
        className="hedding-center line-bottom-0"
        subHeading=""
        categoryCardType="card7"
        categories={AIRBNB_REVIEWS}
        itemPerRow={1}
      />
      :""
      }
      

        {/* SECTION */}
        {/*
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>
        */}

        {/* SECTION */}
        {/*<SectionGridCategoryBox />*/}

        {/* SECTION */}
        {/*
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        */}

        {/* SECTION 1 */}
        

        {/* SECTION */}
        {/*<SectionVideos />*/}

        {/* SECTION */}
        {/*
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        */}
        
      </div>
      {!showFullMapFixed && isWebsiteName=='tinyaway' && (
          <div
            className="flex  items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => showOnMap()}
          >
            <i className="text-lg las la-map"></i>
            <span>{targetlanguage=='zh_tw'?'顯示地圖':'Show map'}</span>
          </div>
        )}
    </div>
  );
}
export default PageHome;
